import React from "react";
import './BlogPost.scss'
import Navbar from '../Navbar/Navbar'

function BlogPost(props) {
    return (
        <div>
            <Navbar {...props}  classes={{}}></Navbar>
            This is blog {props.match.params.blogId}
        </div>
    )
}

export default BlogPost
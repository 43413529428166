import React, { Component } from "react"
import './Landing.scss'
import background_landscape from '../../assets/background/Lake_Bled_June_2019_Sunrise_long.mp4'
// import background_portrait from '../../assets/background/bled_sunrise_cinemagraph_v2_long.mp4'
import background_portrait from '../../assets/background/bled_sunrise_cinemagraph_v2_long.webm'
// import background_landscape_poster from '../../assets/background/Lake_Bled_June_2019_Sunrise.jpg'
// import background_portrait_poster from '../../assets/background/bled_sunrise_bridge.jpg'
import { Link } from "react-router-dom";

class Landing extends Component {

    constructor(props) {
        super()
    }

    componentDidMount() {
        console.log('Landing page loaded');
    }

    goToHome() {
        console.log('it works');
    }

    render() {
        return (
            <div className='Landing'>
                <div className="main-content">
                    <video autoPlay loop muted preload='auto' id="background-video">
                        <source src={window.matchMedia("(min-width : 768px)").matches ? background_landscape : background_portrait} type="video/mp4"/>
                    </video>
                    <div className="title">
                        <svg width={732/3} height={113/3} viewBox="0 0 732 113" fill="none" xmlns="http://www.w3.org/2000/svg" id="logo">
                            <path d="M73.416 83.68H29.64L21.576 106H7.75201L44.04 6.20799H59.16L95.304 106H81.48L73.416 83.68ZM69.672 73.024L51.528 22.336L33.384 73.024H69.672Z" stroke="white" strokeWidth="5" mask="url(#path-1-outside-1)"/>
                            <path d="M124.223 95.344H159.359V106H111.119V5.63199H124.223V95.344Z" stroke="white" strokeWidth="5" mask="url(#path-1-outside-1)"/>
                            <path d="M240.38 35.008C240.38 43.36 237.5 50.32 231.74 55.888C226.076 61.36 217.388 64.096 205.676 64.096H186.38V106H173.276V5.63199H205.676C217.004 5.63199 225.596 8.36799 231.452 13.84C237.404 19.312 240.38 26.368 240.38 35.008ZM205.676 53.296C212.972 53.296 218.348 51.712 221.804 48.544C225.26 45.376 226.988 40.864 226.988 35.008C226.988 22.624 219.884 16.432 205.676 16.432H186.38V53.296H205.676Z" stroke="white" strokeWidth="5" mask="url(#path-1-outside-1)"/>
                            <path d="M333.994 5.63199V106H320.89V60.496H269.77V106H256.666V5.63199H269.77V49.696H320.89V5.63199H333.994Z" stroke="white" strokeWidth="5" mask="url(#path-1-outside-1)"/>
                            <path d="M415.697 83.68H371.921L363.857 106H350.033L386.321 6.20799H401.441L437.585 106H423.761L415.697 83.68ZM411.953 73.024L393.809 22.336L375.665 73.024H411.953Z" stroke="white" strokeWidth="5" mask="url(#path-1-outside-1)"/>
                            <path d="M504.895 95.344H540.031V106H491.791V5.63199H504.895V95.344Z" stroke="white" strokeWidth="5" mask="url(#path-1-outside-1)"/>
                            <path d="M613.275 83.68H569.499L561.435 106H547.611L583.899 6.20799H599.019L635.163 106H621.339L613.275 83.68ZM609.531 73.024L591.387 22.336L573.243 73.024H609.531Z" stroke="white" strokeWidth="5" mask="url(#path-1-outside-1)"/>
                            <path d="M663.795 5.63199V69.136C663.795 78.064 665.955 84.688 670.275 89.008C674.691 93.328 680.787 95.488 688.563 95.488C696.243 95.488 702.243 93.328 706.563 89.008C710.979 84.688 713.187 78.064 713.187 69.136V5.63199H726.291V68.992C726.291 77.344 724.611 84.4 721.251 90.16C717.891 95.824 713.331 100.048 707.571 102.832C701.907 105.616 695.523 107.008 688.419 107.008C681.315 107.008 674.883 105.616 669.123 102.832C663.459 100.048 658.947 95.824 655.587 90.16C652.323 84.4 650.691 77.344 650.691 68.992V5.63199H663.795Z" stroke="white" strokeWidth="5" mask="url(#path-1-outside-1)"/>
                        </svg>
                        <div>My Blog</div>
                        <button className="outline">
                            <Link to='/home'>Go to blog</Link>
                        </button>
                    </div>
                </div>
            </div>
        )
    }

}

export default Landing;
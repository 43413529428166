import React, { Component } from "react"
import './Navbar.scss'
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Menu, HomeRounded, PhotoLibraryRounded, ExploreRounded, ContactsRounded, TimelineRounded, ArrowRightRounded, ArrowLeftRounded } from '@material-ui/icons';
import icon from '../../assets/icon/icon.svg'
import my_face from '../../assets/image/Milos_Sarakiniko_me.jpeg'

const useStyles = theme => ({
    tileTitle: {
        width: '100%',
        height: 60,
        backgroundColor: 'white',
        fontSize: '28px',
        verticalAlign: 'middle',
        display: 'flex'
    },
    fullList: {
        width: 'auto',
        backgroundColor: 'rgba(0,0,0,0.1)',
        height: '100%'
    },
    center: {
        display: 'flex',
        alignItems: 'center'
    },
    right: {
        fontSize: '16px', 
        marginLeft: 'auto', 
        marginRight: '6px'
    },
    aboutMe: {
        backgroundImage: `url(${my_face})`,
        width: '95%',
        height: '200px',
        backgroundPosition: 'center',
        backgroundSize: 'auto 600px',
        alignSelf: 'center',
        marginBottom: 5,
        backgroundRepeat: 'no-repeat'
    }
})

class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state= {
            top: false,
        }
    }

    gotoPage(route) {
        const {pathname} = this.props.location;
        if(pathname.split('/')[0] == route) {
            return
        }
        this.props.history.push('/'+route)
    }

    toggleDrawer(open) {
        this.setState({ top: open });
    }

    fullList() {
        const { classes } = this.props
        return (
            <div
              className={classes.fullList}
              role="presentation"
              onClick={()=>this.toggleDrawer(false)}
              onKeyDown={()=>this.toggleDrawer(false)}
            >
              <List className='list'>
                <ListItem button key={"Home"} onClick={()=>this.gotoPage('home')}>
                    <ListItemIcon><HomeRounded/></ListItemIcon>
                    <ListItemText primary={"Home"} />
                </ListItem>
                <ListItem button key={"Gallery"} onClick={()=>this.gotoPage('gallery')}>
                    <ListItemIcon><PhotoLibraryRounded/></ListItemIcon>
                    <ListItemText primary={"Gallery"} />
                </ListItem>
                <ListItem button key={"Blog"} onClick={()=>this.gotoPage('blog')}>
                    <ListItemIcon><ExploreRounded/></ListItemIcon>
                    <ListItemText primary={"Blog Post"} />
                </ListItem>
                <ListItem button key={"Timeline"} onClick={()=>this.gotoPage('timeline')}>
                    <ListItemIcon><TimelineRounded/></ListItemIcon>
                    <ListItemText primary={"Timeline"} />
                </ListItem>
                <ListItem button key={"Contact"} className='border-bottom' onClick={()=>this.gotoPage('contact')}>
                    <ListItemIcon><ContactsRounded/></ListItemIcon>
                    <ListItemText primary={"Contact Me"} />
                </ListItem>
                <div>
                    {/* Something goes here */}
                </div>
              </List>

              <div className='list flex'>
                <div className='about-me title'>About me</div>
                <div className={classes.aboutMe}></div>
                <div className='about-me'>
                    Hello world! I am Alpha Lau, a nature enthusiast and amateur landscape photographer who dreams to hike every single mountain in the world.
                    <p></p>
                    If there is any question about anything, feel free to direct or comment on my Instagram @alpha_lau_photography
                </div>
              </div>
            </div>
        );
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.tileTitle}>
                <div className={classes.tileTitle}>
                    <img src={icon} height={60} width={60}/>
                    <span className={`${classes.center}`}>Alpha</span>
                    <div className={`${classes.center} ${classes.right}`}>
                        <span>Menu</span>
                        <Menu style={{height:'100%'}} onClick={()=>this.toggleDrawer(true)}></Menu>
                    </div>
                </div>
                <Drawer anchor="right" open={this.state.top} onClose={()=>this.toggleDrawer(false)}>
                    {this.fullList('top')}
                </Drawer>
            </div>
        )
    }

}

export default withStyles(useStyles)(Navbar);
import React, { Component } from "react"
import './Gallery.scss'
import {
    IonCard, IonCardContent, IonCardHeader, IonFabButton, IonIcon, IonFab, IonModal, IonButton
  } from '@ionic/react';
import firebase from '../../firebase.js';
import { addIcons } from 'ionicons';
import { cloudUpload } from 'ionicons/icons';
import { saveAs } from 'file-saver';
import Navbar from '../Navbar/Navbar'
import UploadModal from './UploadModal/UploadModal'
import { controlModal } from '../../actions/index'
import { connect } from "react-redux";
import ImageComparisonSlider from '../ImageComparisonSlider/ImageComparisonSlider'

const mapDispatchToProps = (dispatch) => {
    return {
        controlModal: isOpen => dispatch(controlModal(isOpen))
    };
}

const mapStateToProps = state => {
    return { isOpen: state.modalReducer.isOpen };
};

class Gallery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            images: [],
            showModal: false
        }
        document.oncontextmenu = ()=>false;
        this.resizeImage = this.resizeImage.bind(this)   // ?????? In Home.js this is not needed
        this.uploadImage = this.uploadImage.bind(this)
        addIcons({
            'md-cloud-upload': cloudUpload.md
        })
    }

    async componentDidMount() {
        const db = firebase.firestore()
        let imagesArr = [];
        const gallery_images_ref = await db.collection('gallery_images').get().then(snapshot=>{
            snapshot.forEach(image=>{
                imagesArr.push({
                    id: image['id'],
                    url: image.data()['url'],
                    description: `This is ${image.data()['name']}.`
                })
            })
        })
        this.setState({
            images: imagesArr
        })
    }

    openFileUploadDialog() {
        document.getElementById('upload-image').click();
    }

    uploadImage(fileObj, fileName, fileType) {
        const ref = firebase.storage().ref();
        var uploadTask = ref.child(`image/my_blog_images/${fileName}`).put(fileObj)
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,(snapshot)=>{
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
        },
        (err)=>{
            console.log('In upload: ',err);
        },
        ()=>{
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL)=>{
                this.writeToDb(downloadURL, fileName.split('.')[0], '')
                console.log('File available at', downloadURL);
            });
        })
    }

    writeToDb(imageUrl,name, description) {
        const db = firebase.firestore()
        db.collection('gallery_images').add({
            name: name,
            url : imageUrl,
            description: description
        });
    }

    controlModal() {
        this.props.controlModal(true)
    }

    resizeImage(e) {
        if(e.target.files.length === 0) return;
        const reader = new FileReader();
        let images = e.target.files[0];
        const fileName = e.target.files[0].name;
        reader.readAsDataURL(images);
        reader.onload = (event)=>{
            const img = new Image();
            img.src = event.target.result;
            img.onload = ()=>{
                const elem = document.createElement('canvas');
                const width = img.width*0.8
                const height = img.height*0.8
                elem.width = width
                elem.height = height;
                const ctx = elem.getContext('2d');
                // img.width and img.height will contain the original dimensions
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob)=>{
                    const file = new File([blob], fileName, {
                        type: 'image/jpeg'
                    });
                    this.uploadImage(file, fileName, 'image/jpeg')
                }, 'image/jpeg', 0.8);
            }
        }
    }

    openModal() {
        
    }

    render() {
        return (
            <div>
                <Navbar {...this.props}  classes={{}}></Navbar>
                {this.state.images.map(image=>{
                    return (
                        <div className='image-card' key={image['id']}>
                            <div className='card-inner-container'>
                                <ImageComparisonSlider width={window.innerWidth*0.96-10*2} height={(window.innerWidth*0.96-10*2)/16*9} beforeImage={image['url']} afterImage={'http://getwallpapers.com/wallpaper/full/f/e/2/1173388-free-download-1920-x-1080-landscape-wallpaper-1920x1080-large-resolution.jpg'}></ImageComparisonSlider>
                            </div>
                            <div className='card-desc'>
                                {image['description']}
                            </div>
                        </div>
                        // <IonCard key={image['id']}>
                        //     <IonCardContent>
                        //         <ImageComparisonSlider beforeImage={image['url']} afterImage={image['url']}></ImageComparisonSlider>
                        //         {/* <img src={image['url']}></img> */}
                        //     </IonCardContent>
                        //     <IonCardHeader>
                        //         {image['description']}
                        //     </IonCardHeader>
                        // </IonCard>
                    )
                })}
                <IonFab className='bottom-right'>
                    <IonFabButton color='dark' onClick={()=>this.controlModal()}><IonIcon name='cloud-upload'></IonIcon></IonFabButton>
                    <input id="upload-image" type="file" name="name" onChange={this.resizeImage} multiple accept="image/*"/>
                </IonFab>
                <UploadModal/>
                {/* <IonModal isOpen={this.state.showModal} showBackdrop="true">
                    <p>This is modal content</p>
                    <IonButton onClick={()=>this.controlModal(false)}>Close Modal</IonButton>
                </IonModal> */}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
import React, { Component } from "react"
import './WorldMap.scss'
import world_json from '../../assets/world-50m.json'
import { geoMercator, geoPath } from "d3-geo"
import { feature } from "topojson-client"
import * as d3 from 'd3'
import * as d3Selection from 'd3-selection'
import * as d3Zoom from 'd3-zoom'

class WorldMap extends Component {

  constructor(props) {
    super(props)
    this.cardRef = React.createRef()
    this.state = {
      width: this.props.width ? this.props.width : window.innerWidth,
      height: 500,
      font_size: 8,
      center: null,
      k:1,
      displayThreshold: 75,
      transform: null,
      worldData: [],
      inputValue: "",
      visitedCountries: ["united kingdom", "netherlands", "denmark", "finland", "norway", "iceland", "germany", "slovenia", "czech rep.", "austria", "croatia", "italy", "spain", "france", "russia", "greece", "hungary", "switzerland", "liechtenstein", "vatican city", "japan", "korea", "china", "malaysia", "taiwan", "singapore"]
      // visitedCountries: []
    }
  }

  componentDidMount() {
      setTimeout(() => {
        this.setState({
          worldData: feature(world_json, world_json.objects.units).features,
          // width: document.getElementsByClassName('ion-page')[1].clientWidth // need fix
        })
        this.initializeMap()
      }, 500);
  }

  handleResize(e) {
    let elem = this.getDOMNode();
    let width = elem.offsetWidth;

    this.setState({
        parentWidth: width
    });
  }

  polygonArea(polygon) {
    if(polygon.length === 1) {
      polygon = polygon[0]
    }
    var i = -1,
        n = polygon.length,
        a,
        b = polygon[n - 1],
        area = 0;
  
    while (++i < n) {
      if(isNaN(polygon[i][0])) {
        area += this.polygonArea(polygon[i])
      } else {
        a = b;
        b = polygon[i];
        area += a[1] * b[0] - a[0] * b[1];
      }
    }
    return area / 2;
  }

  findCountryArea(country) {
    var totalArea = 0;
    //Check if the country has more than one bounding region.
    if (country.geometry.coordinates.length > 1) {
      for (var i=0; i<country.geometry.coordinates.length; i++) {
        totalArea += this.polygonArea(country.geometry.coordinates[i])
      }
    } else {
      totalArea = this.polygonArea(country.geometry.coordinates[0]);
    }
    return totalArea//*510072000/(1000*1000);
  }

  initializeMap() {
    const projection = geoMercator().scale(267,50).translate([ this.state.width / 2, this.state.height / 1.25])
    const path = geoPath().projection(projection);
    const data = feature(world_json, world_json.objects.units).features
    var map_svg = d3Selection.select("#map")
    var g = map_svg.append("g")
    var active = d3Selection.select(null)
    const zoom = d3Zoom.zoom()
                  .scaleExtent([1,15])
                  .on("zoom",  () => {
                    g.attr("transform", d3Selection.event.transform)
                    g.selectAll("text")
                    .attr("font-size", ()=>{
                      if(this.state.font_size/d3.event.transform.k < 1) {
                        return 1+"px";
                      }
                      return this.state.font_size/d3.event.transform.k+"px"
                    })
                    d3.selectAll('text')
                      .attr('opacity', function(d) {
                        if (d3.event.transform.k > d.scaleThreshold) {
                          return d.opacityScale(d3.event.transform.k);
                        } 
                        return 0;
                      });
                })
    map_svg.call(zoom)
    var tooltip = d3Selection.select(".tooltip");
    var handleClick = (d) => {
      this.setState({center:d})
      g.selectAll("path").classed("active", this.state.center && (d => { return d === this.state.center; }));
      var bounds = path.bounds(d),
          dx = bounds[1][0] - bounds[0][0],
          dy = bounds[1][1] - bounds[0][1],
          x = (bounds[0][0] + bounds[1][0]) / 2,
          y = (bounds[0][1] + bounds[1][1]) / 2,
          scale = Math.max(1, Math.min(8, 0.9 / Math.max(dx / this.state.width, dy / this.state.height))),
          translate = [this.state.width / 2 - scale * x, this.state.height / 2 - scale * y];
      map_svg.transition()
              .duration(750)
              .call( zoom.transform, d3Zoom.zoomIdentity.translate(translate[0],translate[1]).scale(scale) );
      // var x, y;
      // if (d && this.state.center !== d) {
      //   var centroid = path.centroid(d);
      //   x = centroid[0];
      //   y = centroid[1];
      //   this.setState({k:3.5})
      //   this.setState({center:d})
      //   g.transition().duration(750).selectAll("text").attr("font-size", this.state.font_size / this.state.k + "px");
      // } else {
      //   x = this.state.width / 2;
      //   y = this.state.height / 2;
      //   this.setState({k:1})
      //   this.setState({center:null})
      //   g.transition().duration(750).selectAll("text").attr("font-size", this.state.font_size + "px");
      // }
      // g.selectAll("path")
      //        .classed("active", this.state.center && (d => { return d === this.state.center; }));
      // g.transition()
      //        .duration(750)
      //        .attr("transform", "translate(" + this.state.width / 2 + "," + this.state.height / 2 + ")scale(" + this.state.k + ")translate(" + -x + "," + -y + ")")
      //        .style("stroke-width", 1.5 / this.state.k );
    }
    g.selectAll("path")
          .data(data)
          .enter()
          .append("path")
          .attr("stroke","green")
          .attr("stroke-width",0.1)
          .attr("fill", "#f2f2f2")
          .attr("d", path)
          .attr("id", (d)=>d.properties.name)
          .on("mouseover",function(d,i){
            d3.select(this).attr("fill","grey").attr("stroke-width",0.1);
              return tooltip.style("hidden", false).html(d.properties.name);
          })
          .on("mousemove",function(d){
            tooltip.classed("hidden", false)
                    .style("top", (d3.event.pageY) + "px")
                    .style("left", (d3.event.pageX + 10) + "px")
                    .html(d.properties.name);
          })
          .on("mouseout",function(d,i){
            d.properties.visited ? d3.select(this).attr("fill","aqua").attr("stroke-width",0.1) : d3.select(this).attr("fill","#f2f2f2").attr("stroke-width",0.1);
            tooltip.classed("hidden", true);
          });
    g.selectAll("text")
      .data(data)
      .enter()
      .append("text")
      .attr("class","label")
      .attr("font-size", this.state.font_size+"px")
      .attr("fill", "red")
      // .attr("transform", function(d) { return "translate(" + path.centroid(d) + ")"; })
      .attr('x', d => {return projection(d3.geoCentroid(d))[0];})
      .attr('y', d => { return projection(d3.geoCentroid(d))[1];})
      .text(function(d) { return d.properties.name;} )
      .each((d) => {
        // compute the scale threshold for this element. i.e. how big does the scale need to be before I should display
        d.scaleThreshold = Math.sqrt(this.state.displayThreshold / this.findCountryArea(d));
        d.opacityScale = d3.scaleLinear()
          .domain([d.scaleThreshold, d.scaleThreshold * 1.3])
          .range([0, 1]);
      })
      .attr('opacity', function(d) {
        if (d.scaleThreshold < 1) {
          return 1;
        }
        return 0;
      })
      .on("click", handleClick)

    d3Selection.select("g")
            .selectAll("path")
            .attr('fill',(d)=>{
              if(this.state.visitedCountries.includes(d.properties.name.toLowerCase())) {
                d.properties.visited = true;
                return "aqua";
              }
              return "#f2f2f2";
            })
  }

  render() {
    return (
      // <>
      //   <IonContent>
      //     <IonCard ref={this.cardRef}>
      //       <IonCardContent className='map-card' id='cardRef'>
      //         <div>
      //           <svg id="map"></svg>
      //           <div className="tooltip"></div>
      //         </div>
      //       </IonCardContent>
      //       <ion-card-header>
      //         <ion-card-subtitle>A quick graspe of where I have been to</ion-card-subtitle>
      //         <ion-card-title>{'Countries That I have Been To'.toUpperCase()}</ion-card-title>
      //       </ion-card-header>
      //     </IonCard>
      //   </IonContent>
      // </>
      <div className='svg'>
        <svg height={this.props.height+'px'} id="map"></svg>
        {/* <div className="tooltip"></div> */}
      </div>
    )
  }
}

export default WorldMap
import React, { Component } from "react"
import './UploadModal.scss'
import {
    IonModal, IonButton
  } from '@ionic/react';
import firebase from '../../../firebase.js';
import { saveAs } from 'file-saver';
import { connect } from "react-redux";
import { controlModal } from '../../../actions/index'

const mapDispatchToProps = (dispatch) => {
    return {
        controlModal: isOpen => dispatch(controlModal(isOpen))
    };
}

const mapStateToProps = state => {
    return { isOpen: state.modalReducer.isOpen };
};

class UploadModal extends Component {

    constructor(props) {
        super(props);
    }

    openFileUploadDialog() {
        document.getElementById('upload-image').click();
    }

    uploadImage(fileObj, fileName, fileType) {
        const ref = firebase.storage().ref();
        var uploadTask = ref.child(`image/my_blog_images/${fileName}`).put(fileObj)
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,(snapshot)=>{
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
        },
        (err)=>{
            console.log('In upload: ',err);
        },
        ()=>{
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL)=>{
                this.writeToDb(downloadURL, fileName.split('.')[0], '')
                console.log('File available at', downloadURL);
            });
        })
    }

    writeToDb(imageUrl,name, description) {
        const db = firebase.firestore()
        db.collection('gallery_images').add({
            name: name,
            url : imageUrl,
            description: description
        });
    }

    controlModal() {
        this.props.controlModal(false)
    }

    resizeImage(e) {
        if(e.target.files.length === 0) return;
        const reader = new FileReader();
        let images = e.target.files[0];
        const fileName = e.target.files[0].name;
        reader.readAsDataURL(images);
        reader.onload = (event)=>{
            const img = new Image();
            img.src = event.target.result;
            img.onload = ()=>{
                const elem = document.createElement('canvas');
                const width = img.width*0.8
                const height = img.height*0.8
                elem.width = width
                elem.height = height;
                const ctx = elem.getContext('2d');
                // img.width and img.height will contain the original dimensions
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob)=>{
                    const file = new File([blob], fileName, {
                        type: 'image/jpeg'
                    });
                    this.uploadImage(file, fileName, 'image/jpeg')
                }, 'image/jpeg', 0.8);
            }
        }
    }

    render() {
        return (
            <div>
                <IonModal isOpen={this.props.isOpen} showBackdrop="true">
                    <p>This is modal content</p>
                    <IonButton onClick={()=>this.controlModal(false)}>Close Modal</IonButton>
                </IonModal>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadModal);
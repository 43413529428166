import React, { Component } from "react"
import './Home.scss'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import photo from '../../assets/background/Konigsee_woods_warm_fall.jpg'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import WorldMap from "../WorldMap/WorldMap";
import { PhotoAlbumRounded, TimelineRounded, ArrowRightRounded, ArrowLeftRounded } from '@material-ui/icons';
import Navbar from '../Navbar/Navbar'

const useStyles = theme => ({
    root: {
        '& div': {
            position: 'relative'
        },
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: 'rgba(255,255,255,1)',
        height: '100%',
        fontFamily: "'Rubik', sans-serif",
        fontWeight: 700,
        fontSize: 16,
        letterSpacing: 1.5
    },
    tileTitle: {
        width: '100%',
        height: 60,
        backgroundColor: 'white',
        fontSize: '28px',
        verticalAlign: 'middle',
        display: 'flex'
    },
    gridList: {
      width: '100%',
      height: '100%',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    tileHalfScreenSize: {
        width: window.innerWidth / 2 - 5*2,
        height: window.innerWidth / 2 - 5*2,
        backgroundColor: 'aqua',
        margin: 5,
        marginTop: 0,
    },
    tileMapMobile: {
        width: window.innerWidth - 15*2,
        height: 400,
        backgroundColor: 'rgba(0,0,0,0.0)',
        marginBottom: 20,
    },
    tileFullWidthSquare: {
        '&:hover .leftArrow': {
            position: 'absolute',
            zIndex: 1,
            fontSize: 70,
            color: 'white',
            left: 0,
            top: '50%',
            transform: "translate(0,-50%)",
            display: 'inline-block !important'
        },
        '&:hover .rightArrow': {
            position: 'absolute',
            zIndex: 1,
            fontSize: 70,
            color: 'white',
            right: 0,
            top: '50%',
            transform: "translate(0,-50%)",
            display: 'inline-block !important'
        },
        width: window.innerWidth,
        height: window.innerWidth,
    },
    tileMetaDiv: {
        width: window.innerWidth - 15*2,
        height: 110,
        backgroundColor: 'rgba(0,0,0,0.0)'
    },
    horizontalList: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
        width: '100%',
        height: '100%',
        marginTop: '22px'
    },
    horizontalListItem: {
        color: 'black',
        display: 'inline-block',
        height: '100%',
        textAlign: 'left',
        verticalAlign: 'middle'
    },
    metaDiv: {
        display: 'block',
        color: '#00e6e6'
    },
    tileImage: {
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundImage: `url(${photo})`,
        color: 'aqua'
    },
    largeIcon: {
        fontSize: 75,
    },
    center: {
        position: 'absolute !important',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    bottomLeft: {
        position: 'absolute !important',
        bottom: 0,
        left: 0,
        marginBottom: 5,
        marginLeft: 5
    },
})

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class Home extends Component {

    constructor(props) {
        super(props)
        this.state= {
            isMobile: window.matchMedia("(max-width: 412px)").matches,
            top: false,
            activeStep: 0
        }
    }

    componentDidMount() {
        console.log('Home page loaded');
    }

    gotoPage(route) {
        const {pathname} = this.props.location;
        if(pathname.split('/')[0] == route) {
            return
        }
        this.props.history.push(route)
    }

    handleStepChange(step) {
        this.setState({
            activeStep: step
        });
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <Navbar {...this.props}  classes={{}}></Navbar>
                <div className={classes.tileFullWidthSquare}>
                    {this.state.isMobile?'':<ArrowLeftRounded className='leftArrow' onClick={()=>{this.handleStepChange((5 + this.state.activeStep - 1)%5)}}/>}
                    {this.state.isMobile?'':<ArrowRightRounded className='rightArrow' onClick={()=>{this.handleStepChange((this.state.activeStep + 1)%5)}}/>}
                    <AutoPlaySwipeableViews
                            axis={'x'}
                            index={this.state.activeStep}
                            onChangeIndex={(step)=>{this.handleStepChange(step)}}
                            enableMouseEvents
                            className={classes.tileFullWidthSquare}
                            interval={5000}
                        >
                        <div key={0} className={`${classes.tileImage} ${classes.tileFullWidthSquare}`} onClick={()=>{this.gotoPage(`blog/${1}`)}}>Blog 1</div>
                        <div key={1} className={`${classes.tileImage} ${classes.tileFullWidthSquare}`} onClick={()=>{this.gotoPage(`blog/${2}`)}}>Blog 2</div>
                        <div key={2} className={`${classes.tileImage} ${classes.tileFullWidthSquare}`} onClick={()=>{this.gotoPage(`blog/${3}`)}}>Blog 3</div>
                        <div key={3} className={`${classes.tileImage} ${classes.tileFullWidthSquare}`} onClick={()=>{this.gotoPage(`blog/${4}`)}}>Blog 4</div>
                        <div key={4} className={`${classes.tileImage} ${classes.tileFullWidthSquare}`} onClick={()=>{this.gotoPage(`blog/${5}`)}}>Blog 5</div>
                    </AutoPlaySwipeableViews>
                </div>
                <div className={classes.tileMetaDiv}>
                    <div>
                        <div className={classes.horizontalList}>
                            <div className={classes.horizontalListItem}>
                                <div>
                                    <span className={classes.metaDiv}>50 +</span>
                                    <span className={classes.metaDiv}>{'cities'.toUpperCase()}</span>
                                    <span className={classes.metaDiv}>{'visited'.toUpperCase()}</span>
                                </div>
                            </div>
                            <div className={classes.horizontalListItem}>
                                <div>
                                    <span className={classes.metaDiv}>25 +</span>
                                    <span className={classes.metaDiv}>{'countries'.toUpperCase()}</span>
                                    <span className={classes.metaDiv}>{'visited'.toUpperCase()}</span>
                                </div>
                            </div>
                            <div className={classes.horizontalListItem}>
                                <div>
                                    <span className={classes.metaDiv}>2</span>
                                    <span className={classes.metaDiv}>{'continents'.toUpperCase()}</span>
                                    <span className={classes.metaDiv}>{'visited'.toUpperCase()}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.tileMapMobile}>
                    <WorldMap height='400' width='350'></WorldMap>
                </div>
                <div className={classes.tileHalfScreenSize} onClick={()=>{this.gotoPage(`gallery`)}}>
                    <PhotoAlbumRounded className={`${classes.center} ${classes.largeIcon}`}/>
                    <div className={`${classes.bottomLeft}`}>Gallery</div>
                </div>
                <div className={classes.tileHalfScreenSize}>
                    <TimelineRounded className={`${classes.center} ${classes.largeIcon}`}/>
                    <div className={`${classes.bottomLeft}`}>Timeline</div>
                </div>
            </div>
        )
    }

}

export default withStyles(useStyles)(Home);
import React, { Component } from 'react';
import './App.scss';
import WorldMap from './components/WorldMap/WorldMap'
import { Route, withRouter, Router } from 'react-router-dom'
import Landing from './components/Landing/Landing';
import Home from './components/Home/Home';
import Gallery from './components/Gallery/Gallery'
import BlogPost from './components/BlogPost/BlogPost';

class App extends Component {

  constructor(props) {
    super();
  }

  render() {
    return (
      <Router history={this.props.history}>
        <Route path='/' exact component={Landing}></Route>
        {/* <Route path='/map' exact component={WorldMap}></Route> */}
        <Route path='/home' exact component={Home}></Route>
        <Route path='/gallery' exact component={Gallery}></Route>
        <Route path='/blog/:blogId' component={BlogPost}></Route>
      </Router>
      // <IonApp>
      //   <IonReactRouter>
      //     <IonHeader>
      //       <IonToolbar color='dark'>
      //         <IonTitle>Menu</IonTitle>
      //         <IonMenuButton slot="start"></IonMenuButton>
      //       </IonToolbar>
      //     </IonHeader>
      //     <IonContent>
      //       <IonSplitPane contentId="main">
      //         <Menu disabled={false} history={this.props.history}></Menu>
      //         <IonPage id="main">
      //           <IonRouterOutlet>
      //             <Route exact path="/" component={Home}></Route>
      //             <Route exact path="/gallery" component={Gallery}></Route>
      //           </IonRouterOutlet>
      //         </IonPage>
      //       </IonSplitPane>
      //     </IonContent>
      //   </IonReactRouter>
      // </IonApp>
    );
  }
}

export default withRouter(App);

const initialState = {
    isOpen: false
}

const modalReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'CONTROL_MODAL':
            return {
                ...state,
                isOpen: action.isOpen
            }
        default:
            return state
    }
}

export default modalReducer